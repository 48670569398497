import {
  CountryCode,
  NumberFormat,
  ParsedNumber,
  formatNumber,
  getCountryCallingCode,
  isPossibleNumber,
  parseNumber,
} from 'libphonenumber-js';

import { ECountry } from '@core/type';

const formatPhoneNumber = (locale: ECountry, format: NumberFormat, number: string): string => {
  if (!number) {
    return null;
  }

  if (isPossibleNumber(number, locale as CountryCode)) {
    const parsedNumber = parseNumber(number, locale as CountryCode);

    if (Object.keys(parsedNumber).length > 0) {
      return formatNumber(parsedNumber as ParsedNumber, format);
    }
  }
  return null;
};

export const getInternationalPrefix = (locale: ECountry) =>
  Number(getCountryCallingCode(locale as CountryCode));

export const formatPhoneNumberNational = (locale: ECountry, number: string): string => {
  return formatPhoneNumber(locale, 'NATIONAL', number);
};

export const formatPhoneNumberInternational = (locale: ECountry, number: string): string => {
  return formatPhoneNumber(locale, 'INTERNATIONAL', number);
};
