import { forwardRef, Ref } from 'react';

import { EColor, EPosition, ESize } from '@core/type';
import { getInternationalPrefix } from '@core/util';

import { IconCountry } from '../icon';
import { Box } from '../layout';
import { SelectBase } from './SelectBase';
import { SelectOption } from './SelectOption';
import { ICountry, SelectPhoneCodeProps } from './interface-select';

const SelectPhoneCodeOptionLabel = ({ name, iso }: ICountry) => (
  <Box display={'flex'} alignItems={'center'}>
    <Box width={20} height={20} flexShrink={'0'}>
      <IconCountry country={iso} width={20} height={20} />
    </Box>
    <Box marginLeft={'10px'}>{name}</Box>
  </Box>
);

const SelectPhoneCodeOptionContent = ({ iso }: ICountry) => (
  <Box display={'flex'} alignItems={'center'}>
    <Box width={20} height={20} flexShrink={0}>
      <IconCountry country={iso} width={20} height={20} />
    </Box>
    <Box marginLeft={'10px'}>{`(+${getInternationalPrefix(iso)})`}</Box>
  </Box>
);

const SelectPhoneCodeContainer = (
  { countries, ...restProps }: SelectPhoneCodeProps,
  ref: Ref<HTMLInputElement>,
) => (
  <Box minWidth={'130px'}>
    <SelectBase
      size={ESize.MD}
      padding={'0 16px'}
      {...restProps}
      ref={ref}
      name={'phoneCode'}
      Option={SelectOption}
      iconProps={{ size: ESize.MD }}
      verticalPositionProps={{
        [EPosition.TOP]: {
          top: 'auto',
          bottom: '48px',
        },
        [EPosition.BOTTOM]: {
          top: '48px',
          bottom: 'auto',
        },
      }}
      options={countries.map((country) => ({
        value: country.iso as string,
        label: <SelectPhoneCodeOptionLabel {...country} />,
        content: <SelectPhoneCodeOptionContent {...country} />,
      }))}
      backgroundColor={
        restProps.disabled
          ? { commonType: EColor.GRAY, intensity: EColor.A50 }
          : { commonType: EColor.WHITE, intensity: EColor.R900 }
      }
    />
  </Box>
);

export const SelectPhoneCode = forwardRef(SelectPhoneCodeContainer);
