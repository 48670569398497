import { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

import { EColor, EFontWeight, ESize, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout/Box';
import { ProductLabelProps } from './interface-status';

export const ProductLabel = ({ children, labelColor, ...restProps }: ProductLabelProps) => (
  <StatusProductLabelStyled fontWeight={EFontWeight.HEAVY} {...restProps} labelColor={labelColor}>
    {children}
  </StatusProductLabelStyled>
);

export const StatusProductLabelStyled: FC<ProductLabelProps> = styled(Box)(({
  theme: {
    palette,
    structure: {
      productCard: {
        statusLabel: { base, sizes: statusLabelSizeStyles },
      },
    },
  },
  size = ESize.MD,
  labelColor,
}: ProductLabelProps & WithThemeProps) => {
  const { getColor } = getPaletteHandlers(palette);

  return {
    ...base,
    ...statusLabelSizeStyles[size],
    backgroundColor: getColor({ commonType: EColor.WHITE, intensity: EColor.R900 }),
    border: `2px solid ${getColor(labelColor)}`,
    color: getColor(labelColor),
  } as CSSObject;
});
