import { Controller } from 'react-hook-form';

import { ButtonQuantity } from '../../button/ButtonQuantity';
import { ButtonQuantityPropsControlProps } from './interface-form-button';

export const ButtonQuantityControl = ({
  name,
  control,
  ...inputProps
}: ButtonQuantityPropsControlProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <ButtonQuantity
        field={field}
        value={field.value as number}
        onChange={field.onChange}
        {...inputProps}
        name={name}
      />
    )}
  />
);

ButtonQuantityControl.displayName = 'ButtonQuantityControl';
