import useTranslation from 'next-translate/useTranslation';
import withTranslation from 'next-translate/withTranslation';
import { ChangeEventHandler, forwardRef, MouseEventHandler, useCallback } from 'react';

import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EColor, EIcon, ESize, ETypographyVariant, WithTranslation } from '@core/type';

import { InputBase } from '../input/InputBase';
import { Box } from '../layout/Box';
import { Typography } from '../typography';
import { ButtonIcon } from './ButtonIcon';
import { ButtonIconWrapperProps, ButtonQuantityProps } from './interface-button';

const ButtonIconWrapper = ({ isDisabled, type, isSmall, ...restProps }: ButtonIconWrapperProps) => (
  <ButtonIcon
    type={'button'}
    backgroundColor={EColor.SECONDARY}
    borderRadius={'6px'}
    borderColor={{ commonType: EColor.GRAY, intensity: EColor.R100 }}
    borderWidth={'1px'}
    borderStyle={'solid'}
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
    flexShrink={0}
    disabled={isDisabled}
    iconProps={{ type }}
    opacity={isDisabled ? '0.65' : '1'}
    width={isSmall ? '34px' : '48px'}
    height={isSmall ? '34px' : '48px'}
    {...restProps}
  />
);

const ButtonQuantityContainer = forwardRef<HTMLInputElement, ButtonQuantityProps & WithTranslation>(
  (
    {
      value,
      min,
      max,
      onChange,
      size = ESize.LG,
      isPreorder,
      isSell,
      i18n: { t },
      field,
      disabled,
      isMessageAbsolute,
      ...restProps
    },
    ref,
  ) => {
    const { t: tAria } = useTranslation(Translate.common.ARIA);
    const isSmall = size === ESize.SM;
    const isMaxReached = value >= max;
    const { isMobile } = useContextMediaQuery();

    const handlePrevButton: MouseEventHandler<HTMLButtonElement> = () => {
      const newState = value - 1;

      onChange(newState >= min ? newState : value);
    };

    const handleNextButton: MouseEventHandler<HTMLButtonElement> = () => {
      const newState = value + 1;

      onChange(newState <= max ? newState : value);
    };

    const passValueToChangeHandler = useCallback(
      (num: number) => {
        if (num < min) {
          onChange(min);
        } else if (num > max) {
          onChange(max);
        } else {
          onChange(num);
        }
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      [min, max],
    );

    const handleChange: ChangeEventHandler<HTMLInputElement> = ({
      target: { value: newValue },
    }) => {
      passValueToChangeHandler(Number(newValue.replace(/(^0+|\D)/g, '')));
    };

    return (
      <Box position={'relative'}>
        {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
        <Box width={isSmall ? 116 : 144} display={'flex'} alignItems={'center'}>
          <ButtonIconWrapper
            type={EIcon.MINUS}
            onClick={handlePrevButton}
            aria-label={tAria('decrementButton')}
            isDisabled={value <= min || disabled}
            isSmall={isSmall}
          />
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <InputBase
              name={'quantity'}
              aria-label={tAria('quantityInput')}
              textAlign={'center'}
              {...restProps}
              ref={field?.ref || ref}
              value={value ?? ''}
              onChange={handleChange}
              onBlur={() => passValueToChangeHandler(value)}
              disabled={disabled}
            />
          </Box>
          <ButtonIconWrapper
            type={EIcon.PLUS}
            onClick={handleNextButton}
            aria-label={tAria('incrementButton')}
            isDisabled={value >= max || disabled}
            isSmall={isSmall}
          />
        </Box>
        {isMaxReached && (
          <Box
            role={'alert'}
            marginTop={8}
            position={isMessageAbsolute ? 'absolute' : undefined}
            top={isMobile ? '30px' : '34px'}
            right={'0'}
          >
            <Typography variant={ETypographyVariant.CAPTION1} color={EColor.SECONDARY}>
              {isSell && t('buttons.sellQuantityAlert', { maxQuantity: max })}
              {isPreorder && t('buttons.preorderQuantityAlert', { maxQuantity: max })}
              {!isSell && !isPreorder && t('buttons.quantityAlert', { maxQuantity: max })}
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
);

ButtonQuantityContainer.displayName = 'ButtonQuantityContainer';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ButtonQuantity = withTranslation(ButtonQuantityContainer, Translate.common.PRODUCT);
