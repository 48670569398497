import { Controller } from 'react-hook-form';

import { InputCheckbox } from '../../input/InputCheckbox';
import { InputCheckboxControlProps } from './interface-form-input';

export const InputCheckboxControl = ({
  name,
  control,
  ...inputProps
}: InputCheckboxControlProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => {
      return <InputCheckbox field={field} fieldState={fieldState} {...inputProps} name={name} />;
    }}
  />
);

InputCheckboxControl.displayName = 'InputCheckboxControl';
