import { EActionAttribute, EColor, ETypographyVariant } from '@core/type';

import { Box } from '../layout/Box';
import { Typography } from '../typography/Typography';

export type FieldErrorProps = {
  message: string;
};

export const FieldErrors = ({ message }: FieldErrorProps) => {
  return (
    message && (
      <Box
        margin={'0px auto'}
        paddingBottom={0}
        paddingTop={4}
        width={'100%'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        display={'flex'}
        role="alert"
      >
        <Typography
          as={EActionAttribute.SPAN}
          color={EColor.DANGER}
          variant={ETypographyVariant.CAPTION1}
          role="alert"
        >
          {message}
        </Typography>
      </Box>
    )
  );
};

FieldErrors.displayName = 'FieldError';
