import { forwardRef, useEffect, useState } from 'react';

import { useLanguage } from '@core/hook';
import { ECurrency, ELanguageTags } from '@core/type';
import { formatCurrency, formatNumber, getDecimalSeparator } from '@core/util';

import { InputNumberProps } from '../interface';
import { InputTextBase } from './InputText/InputTextBase';

export const InputNumberBase = forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      label,
      size,
      disabled: _disabled,
      field,
      value: _value,
      currency,
      isPositive,
      nbDecimal,
      ...inputProps
    }: InputNumberProps,
    refForward,
  ) => {
    const getStringFormatted = () =>
      field.value ? formatOnBlur(language, field.value, nbDecimal, currency) : '';

    const { language } = useLanguage();
    const [stringNumber, setStringNumber] = useState<string>(getStringFormatted());
    const decimalSeparator = getDecimalSeparator(language);

    useEffect(() => {
      if (field.value && (!stringNumber || field.value !== Number(stringNumber))) {
        setStringNumber(formatOnBlur(language, field.value, nbDecimal, currency));
        field.onBlur();
      }

      if (field.value === 0) {
        setStringNumber('');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value]);

    return (
      <InputTextBase
        label={label}
        {...inputProps}
        ref={field?.ref || refForward}
        value={stringNumber}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          const hasInvalidChar = value.match(new RegExp(`[^\\d${decimalSeparator}-]`));
          const hasInvalidDS = value.match(new RegExp(`[${decimalSeparator}]`, 'g'))?.length > 1;
          const nbMinus = value.match(/-/g)?.length;
          const hasInvalidMinus =
            nbMinus > 1 || (nbMinus === 1 && value.at(0) !== '-') || (nbMinus === 1 && isPositive);
          let resString = value;
          if (!hasInvalidChar && !hasInvalidDS && !hasInvalidMinus) {
            resString = resString.replace(',', '.');
            if (canCheckInput(resString)) {
              const resNumber = +resString;
              if (checkInputNumber(resString, nbDecimal)) {
                setStringNumber(value);
                field.onChange(resNumber);
              }
            } else {
              if (!(nbDecimal === 0 && value.match(/./))) {
                setStringNumber(value);
              }
            }
          }
        }}
        onBlur={() => {
          setStringNumber(
            field.value ? formatOnBlur(language, field.value, nbDecimal, currency) : '',
          );
          field.onBlur();
        }}
        onFocus={() => {
          setStringNumber(
            field.value ? formatOnFocus(field.value, decimalSeparator, nbDecimal) : '',
          );
        }}
      />
    );
  },
);

const canCheckInput = (value: string): boolean => !(value.at(-1) === '.' || value.at(0) === '.');

const checkInputNumber = (value: string, nbDecimal?: number) => {
  const splitDot = value.toString().split('.');
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (nbDecimal && splitDot.length === 2 && splitDot[1].length > nbDecimal) {
    return false;
  }
  return true;
};

const formatOnFocus = (value: number, decimalSeparator: string, nbDecimal: number) => {
  let stringNumber = value.toString();

  if (nbDecimal > 0) {
    const splitDot = stringNumber.toString().split('.');
    stringNumber = stringNumber.replace('.', decimalSeparator);
    if (splitDot.length === 1) {
      stringNumber = `${stringNumber}${decimalSeparator}${'0'.repeat(nbDecimal)}`;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    } else if (splitDot.length === 2 && splitDot[1].length < nbDecimal) {
      stringNumber = `${stringNumber}${'0'.repeat(nbDecimal - splitDot[1].length)}`;
    }
  }
  return stringNumber;
};

const formatOnBlur = (
  language: ELanguageTags,
  value: number,
  nbDecimal: number,
  currency: ECurrency,
) => {
  return currency
    ? formatCurrency(language, currency, value)
    : formatNumber(language, value, { minimumFractionDigits: nbDecimal });
};

InputNumberBase.displayName = 'InputNumberBase';
