import withTranslation from 'next-translate/withTranslation';
import type { FC, MouseEventHandler } from 'react';

import { Translate } from '@core/constant';
import { EActionAttribute, EColor, EIcon, ETypographyVariant } from '@core/type';

import { Box } from '../layout';
import { Link } from './Link';
import { LinkAddToWishlistProps } from './interface-link';

const LinkAddToWishlistContainer: FC<LinkAddToWishlistProps> = ({
  i18n: { t },
  isActive,
  hasText = true,
  onClick,
  isMobile,
  ...props
}) => {
  return (
    <Box onClick={isMobile ? (onClick as unknown as MouseEventHandler<HTMLButtonElement>) : null}>
      <Link
        isIconFill={isActive}
        isIconStroke
        color={EColor.SECONDARY}
        iconColor={EColor.SECONDARY}
        startIcon={EIcon.HEART}
        iconStrokeWidth={1.5}
        as={EActionAttribute.SPAN}
        onClick={isMobile ? null : onClick}
        variant={ETypographyVariant.H6}
        {...props}
      >
        {hasText && (isActive ? t('link.removeFromWishlist') : t('link.addToWishlist'))}
      </Link>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const LinkAddToWishlist = withTranslation(
  LinkAddToWishlistContainer,
  Translate.common.PRODUCT,
);
