import { forwardRef } from 'react';

import { InputNumberProps } from '../interface';
import { FieldError } from './FieldError';
import { InputNumberBase } from './InputNumberBase';
import { InputTextWrapper } from './InputText/InputTextWrapper';

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  ({ size, field, ...inputProps }: InputNumberProps, refForward) => {
    return (
      <>
        <InputTextWrapper size={size} withLabel={!!inputProps.label}>
          <InputNumberBase {...inputProps} ref={field?.ref || refForward} field={field} />
        </InputTextWrapper>
        <FieldError {...inputProps} />
      </>
    );
  },
);

InputNumber.displayName = 'InputNumber';
