import { Controller } from 'react-hook-form';

import { SelectPhoneCode } from '../../select';
import { SelectPhoneCodeControlProps } from './interface-form-select';

export const SelectPhoneCodeControl = ({
  name,
  control,
  ...inputProps
}: SelectPhoneCodeControlProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <SelectPhoneCode field={field} {...inputProps} onChange={field.onChange} />
    )}
  />
);

SelectPhoneCodeControl.displayName = 'SelectPhoneCodeControl';
