import { Controller } from 'react-hook-form';

import { InputNumber } from '../../input';
import { InputNumberControlProps } from './interface-form-input';

export const InputNumberControl = ({ name, control, ...inputProps }: InputNumberControlProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <InputNumber field={field} fieldState={fieldState} {...inputProps} />
    )}
  />
);

InputNumberControl.displayName = 'InputNumberControl';
