import React, { forwardRef } from 'react';

import { InputTextBase } from './InputText/InputTextBase';
import { InputPhoneProps } from './interface-input';

export const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>(
  ({ onChange, value: _value, ...inputProps }: InputPhoneProps, ref) => {
    return (
      <InputTextBase
        type="tel"
        {...inputProps}
        name="phoneNumber"
        placeholder="000 000 0000"
        ref={ref}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          // Call with the deformatted value, so keep only digits
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          const deformatted = value.replace(/\D/g, '');

          onChange(deformatted);
        }}
      />
    );
  },
);

InputPhone.displayName = 'InputPhone';
