import { Controller } from 'react-hook-form';

import { InputPhone } from '../../input';
import { InputPhoneControlProps } from './interface-form-input';

export const InputPhoneControl = ({ name, control, ...inputProps }: InputPhoneControlProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <InputPhone field={field} fieldState={fieldState} {...inputProps} onChange={field.onChange} />
    )}
  />
);

InputPhoneControl.displayName = 'InputPhoneControl';
